@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #F0F2F5;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
  margin-top: 16px;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  line-height: 31px;
  font-family: 'Paytone One', sans-serif;
  font-size: 35px;
  margin: 16px 24px 16px 0;
  color: #fff;
  text-align: center;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.header-container {
  width: 100%;
  text-align: center;
}

.header {
  font-family: 'Paytone One', sans-serif;
  font-size: 5vw;
}

.header-md {
  font-family: 'Paytone One', sans-serif;
  font-size: 3vw;
}

.content-container {
  width: 100%;
  text-align: center;
}

.content {
  margin-bottom: 24px;
}

.paytone {
  font-family: 'Paytone One', sans-serif;
}

.play-container {
  width: 100%;
  text-align: center;
}

.sub {
  font-weight: 200;
  font-style: italic;
  margin-top: -2%;
}
